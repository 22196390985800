.content {
    padding: 60px 10px;
}

.wallet-adapter-button-trigger {
    background: transparent linear-gradient(270deg, #2898D5 0%, #1D4496 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px;
}

.swal2-container .swal2-html-container {
    font-size: 20px;
}