.btn-gray {
    background-color: #7e8299;
    color: #fff;
}

.btn-gray:hover {
    background-color: #5e6278;
    color: #fff;
}

.view-transaction-btn {
    color: var(--link-3-color);
    /* border-bottom: 1px solid #b5b5c3; */
    font-size: 12px;
}

.view-transaction-btn:hover,
.view-transaction-btn i:hover {
    color: #1e4395;
}

.view-transaction-btn i {
    color: #00c5c5;
}

.btn-withdraw,
.btn-activate,
.btn-payment,
.btn-request,
.btn-submit-work,
.btn-reject {
    color: #186ab7;
    border: 2px solid #186ab7 !important;
    padding: 7px !important;
}

.btn-withdraw:hover,
.btn-activate:hover,
.btn-payment:hover,
.btn-release:hover,
.btn-request:hover,
.btn-submit-work:hover,
.btn-accept:hover,
.btn-reject:hover {
    background-color: #1e4395;
    color: #fff;
}

.swal2-styled.swal2-confirm {
    background-color: unset !important;
    background: var(--bg-info-bg) 0% 0% no-repeat padding-box;
    color: #929EAB !important;
    border: 1px solid #1e4395 !important;
    background-image: unset !important;
}

.swal2-styled.swal2-deny {
    background-image: unset !important;
    background-color: unset !important;
    color: #7e8299 !important;
}

.swal2-styled.swal2-deny:focus,
.swal2-styled.swal2-confirm:focus {
    box-shadow: unset;
}

.btn-accept,
.btn-release {
    background-color: #1e4395;
    color: #fff;
    padding: 9px !important;
}


/* .image-upload>input {
    display: none;
} */

.flex-container {
    display: flex;
}

.flex-child {
    flex: 1;
}

.flex-child:first-child {
    margin-right: 20px;
}

.fa,
.fas {
    font-weight: 900;
    font-size: xx-large !important;
}

.attach-file {
    border: 1px solid var(--chat-border-color);
}

#milestonetable td {
    font-weight: 500;
}