.project-firstname-container {
    padding: 25px;
    background: transparent linear-gradient(209deg, #2898D5 0%, #1D4496 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px;
}

.project-firstname {
    font-size: 70px;
    font-weight: 600;
    color: #fff;
    text-transform: capitalize;
}

.project-details-text {
    color: #929EAB;
    font-size: 16px;
    letter-spacing: 0.32px;
    text-align: left;
}