.wallet-vaild-icon,
.balance-vaild-icon {
    color: #198754 !important;
    font-size: 25px !important;
}

.wallet-invaild-icon,
.low-balance-iocn {
    color: #dc3545 !important;
    font-size: 25px !important;
}

.modal-90w {
    width: 70vw;
    max-width: 70vw;
}

.currency-type {
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
    padding: 11px;
    border-radius: 7px;
    border: 1px dotted #ddd
}

.create-contract-btn {
    background: transparent linear-gradient(270deg, #2898D5 0%, #1D4496 100%) 0% 0% no-repeat padding-box;
}

.back-btn {
    background: var(--bg-info-bg) 0% 0% no-repeat padding-box;
    color: #929EAB;
}

.btn-close {
    color: var(--font-4-color);
}

.add-milestone-btn {
    border: 2px solid var(--link-3-color);
    border-radius: 10px;
    color: var(--link-3-color) !important;
    padding: 10px;
    font-size: 14px;
}

.add-milestone-btn i {
    font-size: 17px;
    color: var(--link-3-color) !important;
}

.remove-milestone-btn {
    border: 2px solid #F1416C;
    border-radius: 10px;
    color: #F1416C;
    padding: 8px 12px;
    font-size: 14px;
}

.remove-milestone-btn i {
    font-size: 17px;
    color: #F1416C !important;
}