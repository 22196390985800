.login-gif-animation img {
    width: 700px;
    /* height: 400px; */
    margin: auto;
    /* border-radius: 25px;
    box-shadow: 0 19px 38px rgb(0 0 0 / 30%), 0 15px 12px rgb(0 0 0 / 22%); */
}

.login-container {
    /* background: #fff; */
}

.wallet-adapter-button {
    margin: auto;
}

.wallet-adapter-button-trigger {
    background-color: #1e4395;
}

.term-and-contion-label {
    display: inherit;
}

.disclaimer-bold {
    font-weight: 600;
    padding-left: 5px;
}

#login_footer {
    margin: auto;
}

.disclaimer-justified {
    text-align: justify;
    color: #333;
}

.login-left-container {
    background: transparent linear-gradient(205deg, #2898D5 0%, #1D4496 100%) 0% 0% no-repeat padding-box;
}