.svg-icon,
.btn-view-project {
    color: var(--font-color);
}

.btn-active-milestone,
.btn-view-project {
    background-color: #1e43951a;
}

.btn-active-milestone:hover,
.btn-view-project:hover {
    background: transparent linear-gradient(270deg, #2898D5 0%, #1D4496 100%) 0% 0% no-repeat padding-box;
    color: #fff;
}

.btn-active-milestone:hover .svg-icon {
    color: #fff;
}

.btn-view-all {
    color: var(--font-2-color);
    font-weight: 600;
}

.btn-view-all:hover {
    color: #fff;
}


/* .total-project-card {
    background: transparent linear-gradient( 231deg, #2898D5 0%, #1D4496 100%) 0% 0% no-repeat padding-box;
} */

.bg-gray-100 {
    background-color: #f3f6f9;
}

.text-blue {
    color: #121212;
}

.symbol.symbol-30px {
    width: 100%;
}

.symbol.symbol-30px>img {
    float: right;
}

.symbol.symbol-45px>img {
    width: 35px;
    height: 35px;
}

.d-project-status {
    color: var(--subtext-font-color);
}

.d-sub-heading {
    letter-spacing: 0.4px;
    color: var(--font-color);
    font-size: 16px;
}

.bg-info {
    background-color: var(--bg-info-bg) !important;
    color: var(--font-2-color) !important;
    /* background: transparent linear-gradient(224deg, #2898D5 0%, #1D4496 100%) 0% 0% no-repeat padding-box; */
}

.btn-active-milestone:hover .svg-icon {
    color: var(--font-color);
}

.d-a-m-card {
    background: var(--card-bg-color) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 10px;
}

.icon i {
    color: var(--view-as-buyer-btn-color);
    font-size: 20px;
    font-weight: 600;
}