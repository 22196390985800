/* 1 */

 :root {
    --font-color: #333;
    --font-2-color: #1e4395;
    --font-3-color: #231f20;
    --font-4-color: #C8CED5;
    --subtext-font-color: #929EAB;
    --background-color: #eee;
    --link-color: cornflowerblue;
    --link-2-color: #186AB7;
    --link-3-color: #186AB7;
    --header-bg-color: #fff;
    --side-bar-bg-color: #fff;
    --card-bg-color: #fff;
    --table-header-bg-color: #fff;
    --text-dark: #181c32;
    --view-project-btn-bg: #1e43951a;
    --view-project-btn-color: #1E4395;
    --view-as-buyer-btn-bg-color: #fff;
    --view-as-buyer-btn-active-bg-color: transparent linear-gradient(270deg, #2898D5 0%, #1D4496 100%) 0% 0% no-repeat padding-box;
    --view-as-buyer-btn-border: #C8CED5;
    --view-as-buyer-btn-color: #929EAB;
    --card-header-bg: transparent linear-gradient(231deg, #2898D5 0%, #1D4496 100%) 0% 0% no-repeat padding-box;
    --card-body-bg: #FFFFFF 0% 0% no-repeat padding-box;
    --card-body-inner-bg: #fff 0% 0% no-repeat padding-box;
    --bg-info-bg: #F4F5FA;
    --side-bar-icon-color: #9CA8B5;
    --border-color: #E1E4EC;
    --border-1-color: #E0E2EB;
    --modal-close-icon: url(../images/cancel.png) center/1em auto no-repeat;
    --page-bg-img: url("../images/bg.png");
}


/* 2 */

[data-theme="dark"] {
    --font-color: #fff;
    --font-2-color: #fff;
    --font-3-color: #FFFFFF;
    --font-4-color: #929EAB;
    --subtext-font-color: #929EAB;
    --background-color: #222736;
    --link-color: lightblue;
    --link-2-color: #186AB7;
    --link-3-color: #4380C1;
    --header-bg-color: #262B3C;
    --side-bar-bg-color: #262B3C;
    --card-bg-color: #2A3042;
    --table-header-bg-color: #2A3042;
    --text-dark: #fff;
    --view-project-btn-bg: transparent linear-gradient(270deg, #2898D5 0%, #1D4496 100%) 0% 0% no-repeat padding-box;
    --view-project-btn-color: #fff;
    --view-as-buyer-btn-bg-color: #222736;
    --view-as-buyer-btn-active-bg-color: transparent linear-gradient(270deg, #2898D5 0%, #1D4496 100%) 0% 0% no-repeat padding-box;
    --view-as-buyer-btn-border: #3C445D;
    --view-as-buyer-btn-color: #929EAB;
    --card-header-bg: transparent linear-gradient(231deg, #2898D5 0%, #1D4496 100%) 0% 0% no-repeat padding-box;
    --card-body-bg: #2A3042 0% 0% no-repeat padding-box;
    --card-body-inner-bg: #353C53 0% 0% no-repeat padding-box;
    --bg-info-bg: #353C53;
    --side-bar-icon-color: #9CA8B5;
    --border-color: #3C445D;
    --border-1-color: #e0e2eb3b;
    --modal-close-icon: url(../images/cancel-dark.png) center/1em auto no-repeat;
    --page-bg-img: url("../images/bg-dark.png");
}


/* 3 */

body {
    background-image: var(--page-bg-img);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    /* background-color: var(--background-color); */
    color: var(--font-color);
    background-size: cover;
}

a {
    color: var(--link-color);
}

.profile {
    color: #1e4395;
    font-size: 13px;
    font-weight: 600;
}

.btn-profile-update {
    background-color: #181c32;
    color: #fff;
}

.btn-profile-update:hover {
    background-color: #3f4254;
    color: #fff;
}

.btn-profile-update:focus:not(.btn-active) {
    background-color: #181c32;
    color: #fff;
}

.menu-sub.menu-sub-dropdown {
    z-index: 105;
    position: fixed;
    display: flex;
    inset: 0px 0px auto auto;
    margin: 0px;
    transform: translate3d(-30px, 80px, 0px);
}

.display-flex {
    display: flex;
}

.news {
    /* background-color: #3f4254;
    border-radius: 9999px; */
    padding: 7px;
}

.news-heading {
    background-color: #f1bc00;
    border-radius: 9999px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    font-weight: 600;
}

.news-sub-heading {
    color: #3f4254 !important;
}

.wallet-adapter-button-inside {
    height: 40px !important;
    color: #1e4395 !important;
    border-color: #1e4395 !important;
    background-color: #1e439514 !important;
}

.wallet-adapter-button-insid:hover {
    background-color: unset !important;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: unset;
    color: unset;
}

#basic-nav-dropdown:hover {
    color: #1e4395 !important;
}

.dropdown-item {
    margin: 0px;
    font-size: 14px;
    color: #1e4395;
    font-weight: 500;
    text-align: center;
}

.profile-name {
    font-size: 14px;
    font-weight: 600;
}

.dropdown-item:focus,
.dropdown-item:hover {
    background-color: unset;
}

.header-fixed .header {
    top: 42px;
    padding-left: 180px;
    background: var(--background-color);
}

div.psst {
    position: fixed;
    top: 0px;
    width: 87%;
    z-index: 999;
    padding: 10px 50px 0px 50px;
    background: var(--header-bg-color);
    color: var(--font-color);
    font-size: 15px;
    text-align: center;
    line-height: 1.3;
}

.psst a {
    color: #ffc107;
    font-weight: 400;
}


/* .notifications {
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    color: black;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  
  .notification {
    padding: 5px;
    font-size: 14px;
  } */

.notification-menu {
    z-index: 105;
    position: fixed;
    inset: 0px 0px auto auto;
    margin: 0px;
    transform: translate3d(110px, 0px, 0px) !important;
}

#notification-dropdown::after {
    display: none;
}

.icon-button__badge {
    position: absolute;
    top: 10px;
    right: 20px;
    width: 25px;
    height: 25px;
    background: #1e4395;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.Toastify__toast-container--top-right {
    top: 9em !important;
}

.Toastify__toast-theme--light {
    background-color: #000;
    color: #fff;
}


/* Common Css to all pages */

.card {
    background-color: var(--card-bg-color)
}

.card-label {
    color: var(--font-color) !important;
}

table thead tr {
    background-color: var(--table-header-bg-color);
}

.table td {
    color: var(--font-3-color);
}

.text-dark {
    color: var(--text-dark) !important;
}

.btn-view-project {
    background-color: #1e43951a;
}

.btn-view-project {
    background: var(--view-project-btn-bg);
    color: var(--view-project-btn-color)
}

.view-as-buyer-btn.active,
.view-as-seller-btn.active,
.buyer-dispute-btn.active,
.seller-dispute-btn.active {
    background: var(--view-as-buyer-btn-active-bg-color);
    border: 1px solid var(--view-as-buyer-btn-border) !important;
}

.view-as-buyer-btn,
.view-as-seller-btn,
.buyer-dispute-btn,
.seller-dispute-btn {
    background: var(--view-as-buyer-btn-bg-color);
    background-color: unset !important;
    border: 1px solid var(--view-as-buyer-btn-border) !important;
    color: var(--view-project-btn-color);
}

.total-project-card {
    background: var(--card-header-bg);
}

.card-body {
    background: var(--card-bg-color);
    border-radius: 20px;
}


/* .card-inner-body {
        background: var(--card-body-inner-bg);
    } */

.text-gray-800 {
    color: var(--subtext-font-color) !important;
}

.text-gray-900,
.fw-bolder {
    color: var(--font-color) !important;
}

.text-gray-700 {
    color: var(--subtext-font-color) !important;
}

.bg-white {
    background-color: var(--background-color) !important;
}

.card-title,
.card-title h5 {
    color: var(--subtext-font-color) !important;
}

.separator.separator-dashed,
.table.table-row-gray-300 tr {
    border-bottom-color: var(--subtext-font-color) !important;
}

.create-project-btn {
    background: transparent linear-gradient(270deg, #2898D5 0%, #1D4496 100%) 0% 0% no-repeat padding-box;
}

.nav-link {
    color: var(--font-2-color);
}

.aside {
    z-index: 101 !important;
    overflow: hidden;
    width: 265px;
    transition: left 0.3s ease;
}

.wrapper {
    transition: padding-left 0.3s ease;
    padding-top: 74px;
    padding-left: 265px;
}

.aside .menu>.menu-item>.menu-link .menu-icon i,
.menu-state-primary .menu-item.here>.menu-link .menu-title {
    color: var(--side-bar-icon-color) !important;
}

.aside .menu>.menu-item>.menu-link:hover .menu-icon i,
.menu-state-primary .menu-item.here>.menu-link:hover .menu-title {
    color: var(--link-2-color) !important;
}

.aside .menu>.menu-item.show>.menu-link .menu-icon i,
.menu-state-primary .menu-item.here.show>.menu-link .menu-title {
    color: var(--link-2-color) !important;
}

.menu-state-primary .menu-item.here>.menu-link:hover .menu-title,
.menu-state-primary .menu-item.here>.menu-link .menu-title {
    padding-left: 10px;
}

.card {
    border-radius: 20px;
}

.modal-content {
    background: var(--card-bg-color) 0% 0% no-repeat padding-box;
}

.modal-title {
    color: var(--font-3-color);
}

.modal-header {
    border-bottom: 1px sold var(--border-color);
}

input[type=text],
input[type=number],
select,
textarea {
    background-color: unset !important;
    border: 1px solid var(--chat-border-color) !important;
    border-radius: 10px !important;
    border-color: var(--chat-border-color) !important;
    color: var(--font-3-color) !important;
}

select option {
    background-color: var(--card-bg-color);
}

label {
    color: var(--font-3-color);
}

.submit-btn,
.wallet-adapter-button {
    background: transparent linear-gradient(270deg, #2898D5 0%, #1D4496 100%) 0% 0% no-repeat padding-box;
}

.wallet-adapter-button {
    color: #fff !important;
}

.cancel-btn {
    background: var(--bg-info-bg) 0% 0% no-repeat padding-box;
    color: #929EAB;
}

.btn-close {
    color: var(--font-4-color);
    background: var(--modal-close-icon) !important;
}

.font-color-3 {
    color: var(--font-3-color);
}

.link-3-color {
    color: var(--link-3-color);
}

.font-3-color {
    color: var(--font-3-color);
}

.subtext-font-color {
    color: var(--subtext-font-color);
}

.nav-link.active {
    color: var(--link-3-color) !important;
    border-bottom: 2px solid var(--link-3-color) !important;
}


/* .nav-link {
        color: var(--link-3-color) !important;
    } */

.table.table-row-bordered tr {
    border-bottom-color: var(--border-1-color)
}

.rounded {
    border-radius: 10px !important;
}

.border-gray-300 {
    border-color: var(--border-1-color) !important;
}

.badge {
    border: 2px dashed #186AB7;
    border-radius: 5px !important;
    color: #186AB7;
}

.card .card-header {
    border-bottom: 1px solid var(--border-1-color) !important;
}

.table th {
    color: var(--subtext-font-color);
}

.swal2-popup {
    background: var(--card-bg-color) 0% 0% no-repeat padding-box;
}

.swal2-popup .swal2-title {
    color: var(--font-color);
}

.swal2-popup .swal2-content,
.swal2-popup .swal2-html-container {
    color: var(--subtext-font-color);
}

.card-rounded {
    border-radius: 20px 20px 0px 0px;
}

.h-120px {
    height: 120px;
}

.aside .menu>.menu-item.show>.menu-link {
    border-right: 5px solid #2165ae;
    border-radius: 4px;
}

hr {
    opacity: 0.095;
}


/* Switcher */

.switch-container {
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
}

#checkbox {
    display: none;
}

.toggle {
    height: 45px;
    width: 80px;
    display: grid;
    place-items: center;
    position: relative;
    cursor: pointer;
    background: transparent linear-gradient(270deg, #2898D5 0%, #1D4496 100%) 0% 0% no-repeat padding-box;
    border-radius: 0.475rem;
    box-shadow: 7px 7px 10px #00000018;
}

#graphic {
    width: 30px;
    position: absolute;
    right: 2%;
    transform-origin: center;
    transform-box: fill-box;
    transition: all 0.5s ease-in-out;
}

#graphic>* {
    fill: #fff;
    transition: all 0.5s ease-in-out;
    transform-origin: center;
    transform-box: fill-box;
}

#croissant {
    transform: rotate(360deg);
    opacity: 0;
}

#checkbox:checked+.toggle {
    background: linear-gradient(to top, #0a5b8d, #011638);
}

#checkbox:checked+.toggle #graphic {
    transform: translateX(-150%) rotate(360deg);
}

#checkbox:checked+.toggle #sung {
    opacity: 0;
}

#checkbox:checked+.toggle #croissant {
    transform: scale(1.2);
    opacity: 1;
}

.close-menu {
    float: right;
    padding-top: 7px;
}

.close-menu i {
    font-size: 3rem;
    color: #2165ae;
}

@media (max-width: 991.98px) {
    .show-mobile-menu .aside {
        display: block;
        position: fixed;
        height: 200vh;
        z-index: 9999 !important;
        /* left: -265px; */
    }
    /* .content {
        padding: 50px 10px;
    } */
    .header-fixed .header {
        padding-left: unset;
    }
    .wrapper {
        padding-left: unset;
    }
    div.psst {
        width: 100%;
    }
    .aside-logo {
        padding-left: 10px;
        padding-right: 10px;
    }
    .create-project-btn {
        padding-left: 7px !important;
        padding-right: 7px !important;
    }
    .toggle {
        height: 42px
    }
    .h-55px {
        height: 50px !important;
    }
    .login-gif-animation img {
        width: 250px !important;
    }
    .show-mobile-menu .page {
        position: absolute;
        top: 0px;
        left: 0px;
        bottom: 0px;
        right: 0px;
        background-color: #121212d1;
        /* z-index: 10; */
        height: 700vh;
    }
    .show-mobile-menu .wrapper {
        z-index: -1;
    }
    .modal-90w {
        width: 97%;
        max-width: 97%;
    }
}