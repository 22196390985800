:root {
    --chat-badge-inbox: transparent linear-gradient(224deg, #2898D5 0%, #1D4496 100%) 0% 0% no-repeat padding-box;
    --chat-badge-request: #00C179 0% 0% no-repeat padding-box;
    --chat-badge-connect: #F1416C 0% 0% no-repeat padding-box;
    --chat-left-card-bg: #F4F5FA 0% 0% no-repeat padding-box;
    --sender-chat-card-bg: transparent linear-gradient(270deg, #2898D5 0%, #1D4496 100%) 0% 0% no-repeat padding-box;
    --chat-btn-bg: transparent linear-gradient(223deg, #2898D5 0%, #1D4496 100%) 0% 0% no-repeat padding-box;
    --chat-border-color: #E1E4EC;
}

[data-theme="dark"] {
    --chat-badge-inbox: #257BBF 0% 0% no-repeat padding-box;
    --chat-badge-request: #00C179 0% 0% no-repeat padding-box;
    --chat-badge-connect: #F1416C 0% 0% no-repeat padding-box;
    --chat-left-card-bg: #222736 0% 0% no-repeat padding-box;
    --sender-chat-card-bg: transparent linear-gradient(270deg, #2898D5 0%, #1D4496 100%) 0% 0% no-repeat padding-box;
    --chat-btn-bg: transparent linear-gradient(223deg, #2898D5 0%, #1D4496 100%) 0% 0% no-repeat padding-box;
    --chat-border-color: #3C445D;
}

button#tab-chat-1-tab-inbox.active {
    border: unset;
    border-bottom: 2px solid #186AB7;
    /* border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 5px 10px; */
}

.clr-blue {
    background: var(--chat-badge-inbox) !important;
    border-radius: 25px;
    margin-left: 7px;
}

button#tab-chat-1-tab-inbox,
button#tab-chat-1-tab-message-request,
button#tab-chat-1-tab-send-message-request {
    color: #9CA8B5;
    font-size: 1.1em;
    font-weight: 600;
}

button#tab-chat-1-tab-inbox:focus-visible,
button#tab-chat-1-tab-message-request:focus-visible,
button#tab-chat-1-tab-send-message-request:focus-visible {
    border: unset;
}

button#tab-chat-1-tab-message-request.active {
    border: unset;
    border-bottom: 2px solid #186AB7;
    /* border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 5px 10px; */
}

.clr-green {
    background: var(--chat-badge-request) !important;
    border-radius: 25px;
    margin-left: 7px;
}

button#tab-chat-1-tab-send-message-request.active {
    border: unset;
    border-bottom: 2px solid #186AB7;
    /* border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 5px 10px; */
}

.clr-red {
    background: var(--chat-badge-connect) !important;
    border-radius: 25px;
    margin-left: 7px;
}

button#tab-chat-1-1-tab-received,
button#tab-chat-1-1-tab-sent {
    color: #186AB7;
    font-weight: 500;
    font-size: 1em;
}

button#tab-chat-1-1-tab-received.active {
    border: unset;
    border-bottom: 2px solid #186AB7;
    /* border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 5px 10px; */
}

.clr-yellow {
    background-color: #f1c40f !important;
    margin-left: 7px;
}

button#tab-chat-1-1-tab-sent.active {
    border: unset;
    border-bottom: 2px solid #186AB7;
    /* border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 5px 10px; */
}

.clr-orange {
    background-color: #d35400 !important;
    margin-left: 7px;
}

#kt_chat_contacts_header {
    border-bottom: none !important;
}

.cls_chat_list {
    cursor: pointer;
    margin-top: 15px;
    background: var(--chat-left-card-bg);
    border-radius: 20px;
}

.cls-chat-preview-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 212px;
}

.cls-chat-height {
    height: 46vh !important;
    max-height: 46vh !important;
}


/* .tab-content{
  height: 70vh !important;
  max-height: 70vh !important;
} */

.cls-dv-chat-right {
    /* background: white; */
    border-radius: 5px;
}

#kt_chat_messenger {
    width: 100%;
}

#kt_chat_messenger_header {
    padding: 7px 7px 0 10px !important;
    min-height: 0 !important;
}

.user-online {
    color: #186AB7;
}

.btn-chat-accept-req {
    background-color: #2ecc71;
    color: #fff;
}

.btn-chat-reject-req {
    background-color: #e74c3c;
    color: #fff;
}

.btn-chat-reject-req:hover,
.btn-chat-accept-req:hover {
    color: #fff;
}

.chat-left-card .tab-content {
    padding: 7px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    background-color: unset;
}

.left-card-header {
    color: var(--font-color);
}

.search {
    background-color: unset !important;
    border: 1px solid var(--chat-border-color) !important;
    border-radius: 20px;
    border-color: var(--chat-border-color) !important;
}

.search-icon {
    right: 5%;
}

.symbol.symbol-45px .symbol-label {
    width: 50px;
    height: 50px;
}

.left-chat-person-name {
    color: var(--font-color);
}

.bg-light-success {
    background: transparent linear-gradient(270deg, #2898D5 0%, #1D4496 100%) 0% 0% no-repeat padding-box;
}

.text-success {
    color: #fff !important;
}

.top-30 {
    top: 30% !important;
}

.sender-chat {
    background: var(--sender-chat-card-bg);
    border-radius: 20px 20px 0px 20px !important;
    color: #fff;
}

.receiver-chat {
    background: var(--chat-left-card-bg);
    border-radius: 20px 20px 20px 0px !important;
}

.cls-dv-chat-right .card .card-header {
    border-bottom: unset !important;
}

.chat-send-btn {
    border-radius: 50px;
    height: 40px;
    width: 40px;
    padding: 11px;
    background-color: unset;
    background: var(--sender-chat-card-bg);
    color: #fff;
    margin: auto;
    border: unset;
}

.chat-send-btn i {
    font-size: 1.4rem;
    padding-right: unset;
    color: #fff;
}

.chat-no-content {
    color: var(--font-3-color);
    font-size: 20px;
    padding-top: 100px;
    text-align: center;
}

.send-text-container {
    display: flex;
    align-items: center;
    border: 1px solid var(--chat-border-color);
    border-radius: 10px;
}

.card .card-footer {
    border-top: unset;
}

.send-msg-req-btn {
    background: transparent linear-gradient(270deg, #2898D5 0%, #1D4496 100%) 0% 0% no-repeat padding-box;
}

.chat-left-card .badge {
    border: unset;
    color: #fff;
}

.text-chat {
    border: unset;
}