/* .transaction-hash div{
    max-width: 10em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
} */

.transaction-hash div {
    font-size: 12px;
}

.badge-primary {
    background: unset;
    border: 2px dashed #186AB7;
    border-radius: 5px;
    opacity: 1;
    color: #186AB7;
}

.transaction-hash a {
    color: var(--link-2-color);
}